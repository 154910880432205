
import { defineComponent } from "vue";
import { CFooter } from "@coreui/vue-pro";

export default defineComponent({
  name: "AppFooter",
  components: {
    CFooter,
  },
});
