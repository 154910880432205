import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "toast-body d-flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast align-items-center border-0 text-white show fade-enter-active", {
      'bg-success': _ctx.toast.type === 'success',
      'bg-danger': _ctx.toast.type === 'danger',
      'bg-warning': _ctx.toast.type === 'warning',
      'bg-info': _ctx.toast.type === 'info',
    }]),
    role: "alert",
    "aria-live": "assertive",
    "aria-atomic": "true"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getIcon(_ctx.toast.type)), { class: "flex-shrink-0 me-2" })),
        _createElementVNode("div", null, _toDisplayString(_ctx.toast.message), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn-close btn-close-white me-2 m-auto",
        "aria-label": "Close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toast/remove', _ctx.toast)))
      })
    ])
  ], 2))
}