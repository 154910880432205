
import { defineComponent } from "vue";
import { CContainer } from "@coreui/vue-pro";
import AppSidebar from "@/components/AppSidebar.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    AppFooter,
    AppSidebar,
    AppHeader,
    CContainer,
  },
});
