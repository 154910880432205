export interface ThemeState {
  sidebarVisible: boolean;
  sidebarUnfoldable: boolean;
  theme: null | string;
}

export default {
  namespaced: true,
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: "default",
  } as ThemeState,
  mutations: {
    toggleSidebar(state: ThemeState) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleTheme(state: ThemeState, theme: string) {
      state.theme = theme;
    },
    toggleUnfoldable(state: ThemeState) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state: ThemeState, value: boolean) {
      state.sidebarVisible = value;
    },
  },
  actions: {},
};
