import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ERP",
    redirect: { name: "Login" },
    component: DefaultLayout,
    meta: {
      name: "ERP",
    },
    children: [
      {
        path: "/kalendarz",
        name: "CalendarList",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "@/views/calendar/CalendarList.vue"
          ),
        meta: {
          name: "Kalendarz",
        },
      },
      {
        path: "/klienci",
        name: "ClientList",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/ClientList.vue"
          ),
        meta: {
          name: "Klienci",
        },
      },
      {
        path: "/klienci/:id",
        name: "ClientView",
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/ClientView.vue"
          ),
        meta: {
          name: "Szczegóły klienta",
        },
      },
      {
        path: "/projekty",
        name: "ProjectList",
        component: () =>
          import(
            /* webpackChunkName: "project" */ "@/views/project/ProjectList.vue"
          ),
        meta: {
          name: "Projekty",
        },
      },
      {
        path: "/projekty/:id",
        name: "ProjectView",
        component: () =>
          import(
            /* webpackChunkName: "project" */ "@/views/project/ProjectView.vue"
          ),
        meta: {
          name: "Szczegóły projektu",
        },
      },
      {
        path: "/zapytania",
        name: "InquiryList",
        component: () =>
          import(
            /* webpackChunkName: "inquiry" */ "@/views/inquiry/InquiryList.vue"
          ),
        meta: {
          name: "Zapytania",
        },
      },
      {
        path: "/zapytania/:id",
        name: "InquiryView",
        component: () =>
          import(
            /* webpackChunkName: "project" */ "@/views/inquiry/InquiryView.vue"
          ),
        meta: {
          name: "Szczegóły zapytania",
        },
      },
      {
        path: "/wyszukiwarka-zaawansowana",
        name: "SearchProject",
        component: () =>
          import(
            /* webpackChunkName: "search" */ "@/views/search/SearchProject.vue"
          ),
        meta: {
          name: "Wyszukiwarka zaawansowana",
        },
      },
      {
        path: "/wyszukiwarka-uproszczona",
        name: "SearchSimple",
        component: () =>
          import(
            /* webpackChunkName: "search" */ "@/views/search/SearchSimple.vue"
          ),
        meta: {
          name: "Wyszukiwarka uproszczona",
        },
      },
      {
        path: "/zalaczniki",
        name: "AttachmentList",
        component: () =>
          import(/* webpackChunkName: "attachment" */ "@/views/attachment/AttachmentList.vue"),
        meta: {
          name: "Załączniki",
        },
      },
      {
        path: "/uzytkownicy",
        name: "UserList",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/UserList.vue"),
        meta: {
          name: "Użytkownicy",
        },
      },
      {
        path: "/uzytkownicy/:id",
        name: "UserView",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/UserView.vue"),
        meta: {
          name: "Szczegóły użytkownika",
        },
      },
      {
        path: "/oddzialy",
        name: "BranchList",
        component: () =>
          import(
            /* webpackChunkName: "branch" */ "@/views/branch/BranchList.vue"
          ),
        meta: {
          name: "Oddziały",
        },
      },
      {
        path: "/oddzialy/:id",
        name: "BranchView",
        component: () =>
          import(
            /* webpackChunkName: "branch" */ "@/views/branch/BranchView.vue"
          ),
        meta: {
          name: "Szczegóły oddziału",
        },
      },
      {
        path: "/logi-operacji",
        name: "EventList",
        component: () =>
          import(/* webpackChunkName: "event" */ "@/views/event/EventList.vue"),
        meta: {
          name: "Logi operacji",
        },
        children: [
          {
            path: "demo",
            name: "EventList",
            component: () =>
              import(
                /* webpackChunkName: "event" */ "@/views/event/EventList.vue"
              ),
            meta: {
              name: "Demo",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/logowanie",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/UserLogin.vue"),
  },
  {
    path: "/resetowanie-hasla",
    name: "PasswordResetRequest",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@/views/auth/PasswordResetRequest.vue"
      ),
  },
  {
    path: "/resetowanie-hasla/:token",
    name: "PasswordReset",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/PasswordReset.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404Error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from) => {
  if (
    !store.getters["auth/isAuthenticated"] &&
    to.name !== "Login" &&
    to.name !== "PasswordResetRequest" &&
    to.name !== "PasswordReset"
  ) {
    return { name: "Login" };
  }
  if (store.getters["auth/isAuthenticated"] && to.name === "Login") {
    return { name: "CalendarList" };
  }
});

export default router;
