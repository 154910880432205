import Cookie from "js-cookie";

export interface AuthState {
  id: number | null;
  name: string | null;
  accessToken: string | null;
  branch: number | null;
  remember: boolean;
}

export default {
  namespaced: true,
  state: {
    id: null,
    name: null,
    accessToken: localStorage.getItem("user-token") || null,
    branch: null,
    remember: false,
  } as AuthState,
  mutations: {
    login(state: AuthState, payload: AuthState) {
      state.id = payload.id;
      state.name = payload.name;
      state.accessToken = payload.accessToken;
      state.branch = payload.branch;
      state.remember = payload.remember;
      if (state.remember) {
        const cookie = Cookie.get("data");
        if (cookie !== undefined) {
          Cookie.set("data", cookie, { secure: true, expires: 14 });
        }
      }
    },
    logout(state: AuthState) {
      state.id = null;
      state.name = null;
      state.accessToken = null;
      state.branch = null;
      state.remember = false;
      const cookie = Cookie.get("data");
      if (cookie !== undefined) {
        Cookie.set("data", cookie, { secure: true });
      }
    },
  },
  actions: {},
  getters: {
    isAuthenticated(state: AuthState) {
      return state.accessToken !== null;
    },
  },
};
