
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import {
  BIconCalendarEvent,
  BIconPeople,
  BIconHouseDoor,
  BIconArchive,
  BIconSearch,
  BIconCloudArrowDown,
  BIconPerson,
  BIconBriefcase,
  BIconCardChecklist,
  BIconFileEarmarkSpreadsheet,
} from "bootstrap-icons-vue";
import { CSidebar, CNavGroup } from "@coreui/vue-pro";

export default defineComponent({
  name: "AppSidebar",
  components: {
    InlineSvg,
    BIconCalendarEvent,
    BIconPeople,
    BIconHouseDoor,
    BIconArchive,
    BIconSearch,
    BIconCloudArrowDown,
    BIconPerson,
    BIconBriefcase,
    BIconCardChecklist,
    BIconFileEarmarkSpreadsheet,
    CSidebar,
    CNavGroup,
  },
  computed: {
    sidebarUnfoldable() {
      return this.$store.state.theme.sidebarUnfoldable;
    },
    sidebarVisible() {
      return this.$store.state.theme.sidebarVisible;
    },
  },
});
