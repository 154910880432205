import { createStore, Store } from "vuex";
import theme from "./modules/theme";
import auth from "./modules/auth";
import toast from "./modules/toast";
import meta from "./modules/meta";
import VuexPersistenceSync from "@/store/plugins/VuexPersistenceSync";
import Cookie from "js-cookie";
import router from "@/router";

const vuexLocalEncrypted = new VuexPersistenceSync({
  key: "data",
  encrypted: true,
  modules: ["auth"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  synced: (store: Store<any>, module: string, key: string) => {
    // Refresh router
    if (module === "auth" && key === "accessToken") {
      if (
        !store.getters["auth/isAuthenticated"] &&
        router.currentRoute.value.name !== "Login"
      ) {
        router.push({ name: "Login" });
      } else if (
        store.getters["auth/isAuthenticated"] &&
        router.currentRoute.value.name === "Login"
      ) {
        router.push({ name: "CalendarList" });
      }
    }
  },
});

const vuexLocal = new VuexPersistenceSync({
  key: "store",
  modules: ["theme"],
});

const store = createStore({
  state: {} as { [key: string]: { [key: string]: unknown } },
  mutations: {},
  actions: {},
  modules: {
    theme,
    auth,
    toast,
    meta,
  },
  plugins: [vuexLocalEncrypted.plugin, vuexLocal.plugin],
});

if (store.state.auth.remember) {
  const cookie = Cookie.get("data");
  if (cookie !== undefined) {
    Cookie.set("data", cookie, { secure: true, expires: 14 });
  }
}

export default store;
