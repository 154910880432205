
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import { BIconList, BIconSun, BIconMoon } from "bootstrap-icons-vue";
import AppAccount from "./AppAccount.vue";
import AppBreadcrumb from "./AppBreadcrumb.vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    InlineSvg,
    BIconList,
    BIconSun,
    BIconMoon,
    AppAccount,
    AppBreadcrumb,
  },
});
