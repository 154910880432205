import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue-pro";
import filters from "@/helpers/filters";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://7c71e250992649aea4771084e3ff2097@o4504479640059904.ingest.sentry.io/4504683462066176",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["api.box-haus.test", "api.box-haus.pl", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.use(store);
app.use(router);
app.use(CoreuiVue);
app.config.globalProperties.$filters = filters;
app.mount("#app");
