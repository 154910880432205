
import { defineComponent } from "vue";
import { CDropdown, CDropdownToggle, CDropdownMenu } from "@coreui/vue-pro";
import { BIconLockFill } from "bootstrap-icons-vue";
import avatar from "@/assets/images/avatars/sample-avatar.jpg";

export default defineComponent({
  name: "AppAccount",
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },
  components: {
    BIconLockFill,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
  },
  methods: {
    logout() {
      this.$store.commit("auth/logout");
      this.$router.push({ name: "Login" });
    },
  },
});
